var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
/* eslint-disable react/jsx-key */
import React from 'react';
import sanitizeHtml from 'sanitize-html';
export var jsxJoin = function (items, sep) {
    return items.reduce(function (acc, item, index) {
        if (index === 0) {
            return [item];
        }
        return __spreadArray(__spreadArray([], acc), [sep, item]);
    }, []);
};
export var lineBreakToParagraph = function (str) {
    return str.split('\n').map(function (line) { return React.createElement("p", null, line); });
};
export var lineBreakToBr = function (str) {
    var lines = str
        .split('\n')
        .map(function (line, i) { return React.createElement(React.Fragment, { key: i }, line); });
    return jsxJoin(lines, React.createElement("br", null));
};
export var sanitize = function (str) {
    return sanitizeHtml(str, {
        allowedTags: ['img', 'span'],
        allowedAttributes: {
            img: ['src', 'width', 'height', 'style'],
            span: ['style'],
        },
    });
};
export var lineBreakToParagraphAndHtml = function (str) {
    return str
        .split('\n')
        .map(function (line, index) { return (React.createElement("p", { dangerouslySetInnerHTML: { __html: sanitize(line) }, key: index })); });
};
export var copyToClipborad = function (text) {
    var tmp = document.createElement('div');
    var pre = document.createElement('pre');
    pre.style.webkitUserSelect = 'auto';
    pre.style.userSelect = 'auto';
    tmp.appendChild(pre).textContent = text;
    var s = tmp.style;
    s.position = 'fixed';
    s.right = '200%';
    document.body.appendChild(tmp);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.getSelection().selectAllChildren(tmp);
    document.execCommand('copy');
    document.body.removeChild(tmp);
};
